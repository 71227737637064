export function useDecal () {
  const telegramUrl = 'https://t.me/lobby789'
  const kakaoTalkUrl = 'https://open.kakao.com/o/sGSYMj7g'
  const foreverDomainUrl = `http://${import.meta.env.VITE_FOREVER_DOMAIN}`

  return {
    telegramUrl,
    kakaoTalkUrl,
    foreverDomainUrl,
  }
}
