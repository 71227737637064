<template>
  <div
    class="min-h-screen bg-cover bg-center flex items-center justify-center"
    :style="{'background-image': `url(${bg})`}"
  >
    <div
      class="relative mx-4 md:mx-0"
    >
      <img
        :src="bg2"
        alt="domain"
      >
      <div class="absolute text-white top-4 left-1/2 transform -translate-x-1/2 py-6 md:py-12 flex flex-col items-center gap-2">
        <Logo class="w-38" />
        <div class="mt-5 md:mt-15 flex flex-col gap-2">
          <div
            v-for="domain in domains"
            class="font-bold border w-60 mx-8 py-2 md:(w-100 mx-10) text-center text-2xl hover:underline"
          >
            <a
              :href="`https://${domain}`"
              target="_blank"
            >
              {{ domain }}
            </a>
          </div>
        </div>
        <img
          :src="img"
          alt="img"
          class="mt-2 md:mt-5"
        >
      </div>
      <div class="flex flex-col gap-2 bottom-4 md:(gap-5 bottom-15) items-center absolute left-1/2 transform -translate-x-1/2 bottom-6">
        <a
          :href="telegramUrl"
          target="_blank"
          class="w-full"
        >
          <img
            :src="telegram"
            alt="telegram"
            class="w-full"
          >
        </a>
        <a
          :href="kakaoTalkUrl"
          target="_blank"
          class="w-full"
        >
          <img
            :src="kakaoTakl"
            alt="kakaoTakl"
            class="w-full"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import bg from '@/assets/domain/bg.png'
import bg2 from '@/assets/domain/bg-2.png'
import img from '@/assets/domain/img.png'
import telegram from '@/assets/domain/telegram.png'
import kakaoTakl from '@/assets/domain/kakao-talk.png'
import Logo from '@/components/Logo.vue'
import { useDecal } from '@/composables/useDecal.js'
import axios from 'axios'
import { onMounted, ref } from "vue"

const { telegramUrl, kakaoTalkUrl } = useDecal()
const domains = ref([])
onMounted(async () => {
  const {data} = await axios.get('api/domains')
  domains.value = data.data.data.map(o => o.domain)
})
</script>